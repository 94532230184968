import * as Sentry from '@sentry/nextjs'
import Router from 'next/router'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { BaseScreen, PaymentScreen } from 'shared/api/testania'
import { startScreensIds } from 'shared/config/startScreens'
import { ScreenId } from 'shared/config/types'
import { DEFAULT_GENDER } from 'shared/constants'
import { useAnalytics } from 'shared/providers/AnalyticsProvider'
import { useAuthUser } from 'shared/providers/AuthUserProvider'
import { useClientData } from 'shared/providers/ClientDataProvider'
import { useFlowLoader } from 'shared/providers/FlowLoader/FlowLoader'
import { useLocalization } from 'shared/providers/LocalizationProvider'
import { useOneTrust } from 'shared/providers/OneTrustProvider'
import { Gender } from 'shared/types/user'
import { logger } from 'shared/utils/logger'

import { useInitialNavigation } from './hooks/useInitialNavigation'
import { useOpenNextPage } from './hooks/useOpenNextPage'
import { useSkipToPaymentPage } from './hooks/useSkipToPaymentPage'

import { getStartPageFromExternalSource } from './helpers'
import { FlowManager } from './helpers/FlowManager'

import { INITIAL_STATE } from '../ClientDataProvider/reducer'

interface FlowManagerContextInterface {
  models: {
    currentScreen: BaseScreen | PaymentScreen
    /**
     * Sequence of screens in onboarding flow with {screen}.config.step = true
     */
    onboardingSteps: BaseScreen[]
    /**
     * Sequence of screens in onboarding flow
     */
    onboardingFlow: BaseScreen[]
    paymentFlow: PaymentScreen[]
    isPaymentFlowInProgress: boolean
    isStartPageActive: boolean
  }
  operations: {
    openNextPage: (delay?: number, options?: { isPaidOverride?: boolean }) => void
    skipToPaymentPage: (delay?: number) => void
  }
}

export const FlowManagerContext = createContext<FlowManagerContextInterface | null>(null)

export function useFlowManager() {
  const context = useContext(FlowManagerContext)

  if (!context) {
    throw new Error(`useFlowManager must be used within FlowManagerProvider`)
  }

  return context
}

interface FlowManagerProviderProps {
  children?: React.ReactNode
  currentScreenId: ScreenId
}

export function FlowManagerProvider({ children, currentScreenId }: FlowManagerProviderProps) {
  const [flowManager, setFlowManager] = useState<FlowManager | null>(null)
  const {
    models: { config, abTestVariables, isNewVisitor },
    operations: { resetABTest },
  } = useFlowLoader()
  const [isOnboardingStarted, setIsOnboardingStarted] = useState(false)
  const { state, dispatch } = useClientData()
  const { logEvent, setUserProperties } = useAnalytics()
  const {
    models: { isReady },
  } = useLocalization()
  const {
    operations: { allowAllCookies, enableCookieBannerVisibility },
    models: { allowedCookies, areAllCookiesAllowed },
  } = useOneTrust()
  const {
    models: { user, authStatus },
  } = useAuthUser()
  const finished = useInitialNavigation({
    flowManager,
    allowAllCookies,
    resetABTest,
    authStatus,
    user,
  })
  const {
    state: { gender },
  } = useClientData()

  // create FlowManager instance
  useEffect(() => {
    if (config) {
      // WARN: override start page, DEV only
      if (process.env.APP_ENV !== 'prod') {
        const startPageId = getStartPageFromExternalSource()

        if (startPageId) {
          Object.assign(config, {
            start_page: {
              id: startPageId,
              name: startPageId,
              config: {},
            },
          })
        }
      }

      const flowManagerInstance = new FlowManager({ config })

      if (isNewVisitor) {
        FlowManager.clearCookies()
      }

      setFlowManager(flowManagerInstance)
    }
  }, [config, isNewVisitor])

  // set gender from ab test variable
  useEffect(() => {
    if (abTestVariables) {
      const { gender } = abTestVariables

      if (Object.values(Gender).includes(gender as Gender)) {
        dispatch({ type: 'SET_GENDER', payload: gender as Gender })
        setUserProperties({ gender })
      }
    }
  }, [abTestVariables, dispatch, setUserProperties])

  // prefetch next page for faster client-side transition
  useEffect(() => {
    if (!finished) {
      return
    }

    const isPaid = user?.is_paid
    const userID = user?.id

    const nextScreenUrl = flowManager?.getNextScreenUrl(currentScreenId, { isPaid, userID })

    if (nextScreenUrl) {
      logger.debug('FlowManagerProvider prefetch start:', nextScreenUrl)
      Router.prefetch(nextScreenUrl).then(() => {
        logger.debug('FlowManagerProvider prefetch end:', nextScreenUrl)
      })
    }
  }, [flowManager, user?.is_paid, user?.id, currentScreenId, finished])

  // send onboarding start event (one time per session)
  useEffect(() => {
    if (!isOnboardingStarted && flowManager) {
      const startOnboardingScreen = flowManager.subflowsMap.onboardingFlow[0]

      if (startOnboardingScreen && startOnboardingScreen.id === currentScreenId) {
        setIsOnboardingStarted(true)
        logEvent({ type: 'track', payload: { event: 'onboarding_start' } })
      }
    }
  }, [flowManager, currentScreenId, isOnboardingStarted, logEvent])

  // send event 'start_session'
  useEffect(() => {
    if (authStatus === 'done' && config) {
      logEvent({
        type: 'customData',
        payload: {
          event: `start_session`,
          data: { timestamp: window.APP_START_TIMESTAMP ?? Date.now() },
        },
      })
    }
  }, [logEvent, authStatus, config])

  useEffect(() => {
    if (user) {
      const { id: userId = '' } = user
      Sentry.setUser({ id: String(userId) })

      setUserProperties({ userId: String(userId), gender: gender || DEFAULT_GENDER })
    }
  }, [user, setUserProperties, gender])

  useEffect(() => {
    // toggle cookie banner visibility only after navigation to the first screen
    if (finished) {
      enableCookieBannerVisibility()
    }
  }, [finished, enableCookieBannerVisibility])

  const openNextPage = useOpenNextPage({ flowManager, currentScreenId })
  const skipToPaymentPage = useSkipToPaymentPage({ flowManager, currentScreenId })

  // provide api to components
  const api = useMemo<FlowManagerContextInterface | undefined>(() => {
    if (!flowManager || !currentScreenId || !finished) {
      return
    }

    const isPaymentFlowInProgress = flowManager.checkIsPaymentFlowInProgress(currentScreenId)
    const currentScreen = flowManager.getScreenById(currentScreenId)

    if (!currentScreen) {
      throw new Error('FlowManagerProvider: current screen not found')
    }

    const isStartPageActive = flowManager.checkIsStartPageActive(currentScreenId)

    return {
      models: {
        currentScreen,
        onboardingSteps: flowManager.onboardingSteps,
        onboardingFlow: flowManager.subflowsMap.onboardingFlow,
        paymentFlow: flowManager.subflowsMap.paymentFlow,
        isOnboardingStarted,
        // TODO: remove, replace with withPayment HOC
        isPaymentFlowInProgress,
        isStartPageActive,
      },
      operations: {
        openNextPage,
        skipToPaymentPage,
      },
    }
  }, [flowManager, currentScreenId, finished, isOnboardingStarted, openNextPage, skipToPaymentPage])

  // on payment screen allow all cookies after user confirms choice in cookie banner [omo_w-278]
  useEffect(() => {
    if (api?.models.isPaymentFlowInProgress && allowedCookies && !areAllCookiesAllowed) {
      allowAllCookies()
    }
  }, [allowAllCookies, api, allowedCookies, areAllCookiesAllowed])

  const [userAnalyticsPropsSent, setUserAnalyticsPropsSent] = useState(false)

  useEffect(() => {
    if (userAnalyticsPropsSent || !api?.models.isPaymentFlowInProgress || !user) return

    const isStateEmpty = JSON.stringify(state) === JSON.stringify(INITIAL_STATE)
    if (isStateEmpty) return

    setUserAnalyticsPropsSent(true)
  }, [api, state, user, userAnalyticsPropsSent])

  const isStartScreen = useMemo(() => startScreensIds.includes(currentScreenId), [currentScreenId])

  // allow start screens to be rendered even if api isn't ready
  // so that they are prerendered at build time
  if (!api && !isStartScreen) {
    return null
  }

  return (
    <FlowManagerContext.Provider value={api || null}>
      {isReady && (finished || isStartScreen) && children}
    </FlowManagerContext.Provider>
  )
}
